







































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import { IKeyValue } from '@/interfaces/key-value.interface';
import ClientModel from '@/models/crm/client.model';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import { ClientStatusEnum } from '@/enums/crm/client-status.enum';
import ActivityService from '@/services/crm/activity.service';
import dayjs from '@/plugins/dayjs';
import { IDialogConfig } from '@/interfaces/dialog-config.interface';
import CrmActivityCalendarEventForm from '@/components/crm/activity-calendar-event-form.vue';
import ContentDialog from '@/components/content-dialog.vue';
import UserContactInfo from '@/models/crm/user-contact-info.model';
import ActivityEventModel from '@/models/crm/activity-event.model';
import { toIsoDateString } from '@/utils/formatters/to-iso-date-string';
import ConfirmationDialog from '@/components/confirmation-dialog.vue';
import CrmActivityCalendarEvent from '@/components/crm/activity-calendar-event.vue';
import CrmActivityCalendarEventEmailView from '@/components/crm/activity-calendar-event-email-view.vue';
import { ICalendarEvent } from '@/interfaces/crm/calendar-event.interface';
import ProcessModel from '@/models/crm/process.model';
import RouterService from '@/services/router.service';
import { ClientTypeEnum } from '@/enums/client-type.enum';

type TimeUnit = {
  date: string;
  day: number;
  month: number;
  year: number;
  hour: number;
  minute: number;
};

type ClickEvent = {
  nativeEvent: PointerEvent;
  event: ICalendarEvent;
};

type DragEvent = {
  event: IKeyValue<number>;
  timed: boolean;
};

type ChangeEvent = {
  start: TimeUnit;
  end: TimeUnit;
};

@Component({
  components: {
    ContentDialog,
    CrmActivityCalendarEventForm,
    CrmActivityCalendarEvent,
    ConfirmationDialog,
    CrmActivityCalendarEventEmailView,
  },
})
export default class CrmActivityCalendar extends Vue {
  @inject(InjectionIdEnum.CrmActivityService)
  private activityService!: ActivityService;

  @inject(InjectionIdEnum.RouterService)
  private routerService!: RouterService;

  focus = '';

  type = 'month';

  typeToLabel = {
    month: `${this.$tc('global.month')}`,
    week: `${this.$tc('global.week')}`,
    day: `${this.$tc('global.day')}`,
  };

  events: ICalendarEvent[] = [];

  colors = new Map([
    ['eventBlue', 'rgb(58, 83, 155)'],
    ['eventYellow', 'rgb(247, 202, 24)'],
    ['eventGreen', 'rgb(30, 130, 76)'],
    ['eventRed', 'rgb(192, 57, 43)'],
    ['eventOrange', 'rgb(249, 105, 14)'],
    ['eventPurple', 'rgb(145, 61, 136)'],
  ]);

  labels = [
    {
      description: this.$t('crm.activityCalendar.status.open'),
      colorCode: 'eventBlue',
    },
    {
      description: this.$t('crm.activityCalendar.status.reschedule'),
      colorCode: 'eventYellow',
    },
    {
      description: this.$t('crm.activityCalendar.status.complete'),
      colorCode: 'eventGreen',
    },
    {
      description: this.$t('crm.activityCalendar.status.delayed'),
      colorCode: 'eventRed',
    },
    {
      description: this.$t('crm.activityCalendar.status.holiday'),
      colorCode: 'eventOrange',
    },
    {
      description: this.$t('crm.activityCalendar.status.otherAttendant'),
      colorCode: 'eventPurple',
    },
  ];

  dialogConfig: IKeyValue<IDialogConfig> = {
    confirmation: {
      message: '',
      color: '',
      show: false,
      onChoice: () => {},
    },
    eventForm: {
      show: false,
      id: null,
      cloneId: null,
      startDate: null,
      endDate: null,
      timed: null,
      process: null,
    },
    sendEventEmail: {
      show: false,
      event: null,
    },
  };

  activeEvent: ICalendarEvent | null = null;

  selectedElement: EventTarget | string = '';

  showEvent = false;

  markingAsClose: IKeyValue<boolean> = {};

  showAskForSaleBeforeClose = false;

  isRescheduling = false;

  flagEnviarEmail = false;

  eventModel!: ActivityEventModel | null;

  private lastLoadedPeriod: string[] = [];

  private newEvent: ICalendarEvent | null = null;

  private createEventStart: number | null = null;

  private dragEvent: IKeyValue<number> | null = null;

  private dragTime: number | null = null;

  private loaded = false;

  @Prop({ required: true })
  client!: ClientModel;

  @Prop({ required: true })
  clientType!: ClientTypeEnum;

  @Prop({ required: true })
  userContactInfo!: UserContactInfo;

  @Prop()
  onloadOpen!: number | null;

  mounted(): void {
    setInterval(async () => {
      if (this.events.length) {
        await this.loadEvents(this.lastLoadedPeriod[0], this.lastLoadedPeriod[1], true, false);
      }
    }, 300000);
  }

  async onCalendarChange(event: ChangeEvent): Promise<void> {
    await this.loadEvents(event.start.date, event.end.date);

    if (!this.loaded && this.onloadOpen) {
      this.onEditEvent({ id: this.onloadOpen } as ICalendarEvent);
    }

    this.loaded = true;
  }

  onViewDay({ date }: IKeyValue): void {
    this.focus = date;
    this.type = 'day';
  }

  onSetToday(): void {
    this.focus = '';
  }

  onPrevPeriod(): void {
    if (this.$refs.calendar) {
      const prevKey = 'prev';
      this.$refs.calendar[prevKey]();
    }
  }

  onNextPeriod(): void {
    if (this.$refs.calendar) {
      const nextKey = 'next';
      this.$refs.calendar[nextKey]();
    }
  }

  onCreateEvent(ev: TimeUnit): void {
    if (!this.isConvertedProspect) {
      const date = dayjs(ev.date).toDate();
      this.createCalendarEvent(date, date);
    }
  }

  onShowEvent(ev: ClickEvent): void {
    const { nativeEvent, event } = ev;
    const open = () => {
      this.activeEvent = event;
      this.selectedElement = nativeEvent.target as EventTarget;

      requestAnimationFrame(() => {
        const request = requestAnimationFrame(() => {
          this.showEvent = true;
        });
        return request;
      });
    };

    if (this.showEvent) {
      this.closeEvent();
      requestAnimationFrame(() => requestAnimationFrame(() => open()));
    } else {
      open();
    }

    nativeEvent.stopPropagation();
  }

  async onEditEvent(event: ICalendarEvent): Promise<void> {
    if (!event.id) {
      return;
    }

    this.eventModel = await this.activityService.getEvent(event.id);

    this.closeEvent();

    this.dialogConfig.eventForm.id = event.id;
    this.dialogConfig.eventForm.cloneId = null;
    this.dialogConfig.eventForm.show = true;
    this.dialogConfig.eventForm.process = null;
  }

  onCloneEvent(event: ICalendarEvent): void {
    if (!event.id) {
      return;
    }

    this.closeEvent();

    this.dialogConfig.eventForm.id = null;
    this.dialogConfig.eventForm.cloneId = event.id;
    this.dialogConfig.eventForm.show = true;
  }

  onRescheduleEvent(event: ICalendarEvent): void {
    if (!event.id) {
      return;
    }
    this.isRescheduling = true;
    this.dialogConfig.confirmation.show = true;
    this.dialogConfig.confirmation.color = 'red';
    this.dialogConfig.confirmation.message = this.$t('crm.activityCalendar.dialog.confirmReschedule')
      .toString();
    this.dialogConfig.confirmation.onChoice = (accept: boolean) => {
      this.dialogConfig.confirmation.show = false;
      if (accept) {
        this.onMarkEventAsClose(true, false);
        this.dialogConfig.eventForm.id = null;
        this.dialogConfig.eventForm.cloneId = event.id;
        this.dialogConfig.eventForm.show = true;
      } else {
        this.isRescheduling = false;
      }
    };
  }

  async onSendEmail(event: ICalendarEvent): Promise<void> {
    if (!event.id) {
      return;
    }

    this.eventModel = await this.activityService.getEvent(event.id);

    this.closeEvent();

    this.dialogConfig.sendEventEmail.event = event;
    this.dialogConfig.sendEventEmail.show = true;
  }

  onDeleteEvent(event: ICalendarEvent): void {
    this.beforeDeleteConfirmation(`${this.$t('global.youAreSureDeleteRecord')}`, async (accept: boolean) => {
      if (accept) {
        const loader = this.$loading.show();
        try {
          await this.activityService.delete(event?.id as number);
          this.events = this.events.filter((x) => x.id !== event.id);
        } catch (err) {
          this.$notify.error(err && (err as Error).message);
        } finally {
          loader.hide();
        }
      }
    });
  }

  async onMarkEventAsClose(value: boolean, isNotReschedule = true): Promise<void> {
    if (!value || !this.activeEvent) {
      return;
    }

    this.markingAsClose = { ...this.markingAsClose, [this.activeEvent.id || 0]: true };
    const event = await this.activityService.getEvent(this.activeEvent.id as number);

    if (event.tipoHistorico.flagPassivoVenda && isNotReschedule) {
      this.markingAsClose[this.activeEvent.id || 0] = false;
      this.showAskForSaleBeforeClose = true;
    } else {
      try {
        await this.activityService.close(this.activeEvent.id as number);

        await this.loadEvents(
          toIsoDateString(this.activeEvent.start),
          toIsoDateString(this.activeEvent.end || this.activeEvent.start),
          true,
          true,
        );

        this.closeEvent();
      } catch (err) {
        this.$notify.error(err && (err as Error).message);
      } finally {
        this.markingAsClose[this.activeEvent.id || 0] = false;
      }
    }
  }

  async onCompleteMarkingAsClose(consolidateSale: boolean): Promise<void> {
    this.showAskForSaleBeforeClose = false;

    if (!this.activeEvent) {
      return;
    }

    this.markingAsClose = { ...this.markingAsClose, [this.activeEvent.id || 0]: true };

    try {
      await this.activityService.close(this.activeEvent.id as number, consolidateSale);

      await this.loadEvents(
        toIsoDateString(this.activeEvent.start),
        toIsoDateString(this.activeEvent.end || this.activeEvent.start),
        true,
        true,
      );

      this.showEvent = false;
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    } finally {
      this.markingAsClose[this.activeEvent.id || 0] = false;
    }
  }

  async onAfterSaveEvent(result: ActivityEventModel): Promise<void> {
    if (this.isRescheduling) {
      this.isRescheduling = false;
    }
    await this.loadEvents(toIsoDateString(result.dataHoraInicio), toIsoDateString(result.dataHoraFim), true, true);
    if (result.flagEnviarEmail) {
      this.flagEnviarEmail = true;
      this.eventModel = result;
      const iCalendarEvent = this.events.find((e) => e.id === result.id);
      if (iCalendarEvent) {
        this.onSendEmail(iCalendarEvent);
      }
    }
  }

  onAfterSendEmail(): void {
    this.flagEnviarEmail = false;
  }

  // #region Drag event

  startDrag(ev: DragEvent): void {
    if (ev.event && ev.timed) {
      this.dragEvent = ev.event;
      this.dragTime = null;
    }
  }

  endDrag(): void {
    if (this.newEvent) {
      this.createCalendarEvent(this.newEvent.start, this.newEvent.end, true);
    }

    this.events = this.events.filter((x) => !x.temporary);
    this.newEvent = null;
    this.createEventStart = null;
  }

  cancelDrag(): void {
    if (this.newEvent) {
      const i = this.events.indexOf(this.newEvent);
      if (i !== -1) {
        this.events.splice(i, 1);
      }
    }

    this.newEvent = null;
    this.createEventStart = null;
  }

  startTime(tms: TimeUnit): void {
    const mouse = CrmActivityCalendar.toTime(tms);

    if (this.dragEvent && this.dragTime === null) {
      this.dragTime = mouse - this.dragEvent.start;
    } else {
      this.createEventStart = CrmActivityCalendar.roundTime(mouse);
      this.newEvent = {
        temporary: true,
        name: 'Novo Evento',
        color: 'grey',
        start: new Date(this.createEventStart || 0),
        end: new Date(this.createEventStart || 0),
        timed: true,
      };

      this.events.push(this.newEvent);
    }
  }

  mouseMove(tms: TimeUnit): void {
    const mouse = CrmActivityCalendar.toTime(tms);

    if (this.newEvent && this.createEventStart !== null) {
      const mouseRounded = CrmActivityCalendar.roundTime(mouse, false);
      const min = Math.min(mouseRounded, this.createEventStart);
      const max = Math.max(mouseRounded, this.createEventStart);

      this.newEvent.start = new Date(min);
      this.newEvent.end = new Date(max);
    }
  }

  static roundTime(time: number, down = true): number {
    const roundTo = 10; // minute scale
    const roundDownTime = roundTo * 60 * 1000;

    return down ? time - (time % roundDownTime) : time + (roundDownTime - (time % roundDownTime));
  }

  static toTime(tms: TimeUnit): number {
    return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime();
  }

  // #endregion

  get eventFormDialogTitle(): string {
    let titleKey = 'crm.activityCalendar.dialog.calendarEventForm.newTitle';

    if (this.dialogConfig.eventForm.id) {
      titleKey = 'crm.activityCalendar.dialog.calendarEventForm.editTitle';
    } else if (this.dialogConfig.eventForm.cloneId) {
      titleKey = 'crm.activityCalendar.dialog.calendarEventForm.duplicateTitle';
    }

    return `${this.$t(titleKey)}`;
  }

  get sendEventEmailDialogTitle(): string {
    const event = this.dialogConfig.sendEventEmail.event as ICalendarEvent;
    const title = event && event.name;
    return `${this.$t('crm.activityCalendar.dialog.activityCalendarEventView.title', { title })}`;
  }

  get isConvertedProspect(): boolean {
    return this.client.situacao === ClientStatusEnum.Converted;
  }

  private closeEvent(): void {
    this.showEvent = false;
    // workaround to force detach v-menu activator
    this.selectedElement = '.empty';
  }

  private beforeDeleteConfirmation(message: string, onChoice: CallableFunction): void {
    this.dialogConfig.confirmation.message = message;
    this.dialogConfig.confirmation.color = 'red';
    this.dialogConfig.confirmation.onChoice = onChoice;
    this.dialogConfig.confirmation.show = true;
  }

  private createCalendarEvent(startDate: Date, endDate?: Date, timed?: boolean): void {
    this.dialogConfig.eventForm.id = null;
    this.dialogConfig.eventForm.cloneId = null;
    this.dialogConfig.eventForm.startDate = startDate;
    this.dialogConfig.eventForm.endDate = endDate;
    this.dialogConfig.eventForm.timed = timed;

    const currentRoute = this.routerService.route();
    const processId = currentRoute.query && currentRoute.query.processId;

    if (processId) {
      const process = new ProcessModel();
      process.id = processId as unknown as number;
      this.dialogConfig.eventForm.process = process;
    }

    this.dialogConfig.eventForm.show = true;
  }

  private async loadEvents(start: string, end: string, ignoreCache = false, attachEvents = false): Promise<void> {
    // If is change to period lowest or equal than last loaded period, do nothing
    const lastStart = this.lastLoadedPeriod[0];
    const lastEnd = this.lastLoadedPeriod[1];
    if (!ignoreCache && lastStart && lastEnd) {
      const isStartWithinPeriod = start >= lastStart && start <= lastEnd;
      const isEndWithinPeriod = end <= lastEnd && end >= lastStart;
      if (isStartWithinPeriod && isEndWithinPeriod) {
        return;
      }
    }

    const loader = this.$loading.show();
    try {
      const clientId = this.clientType === ClientTypeEnum.Prospect ? this.client.codCliente : this.client.cnpjCpf;
      const calendarEvents = await this.activityService.getCalendarEventsByClientType(
        clientId,
        start,
        end,
        this.clientType,
      );
      const events: ICalendarEvent[] = attachEvents ? this.events : [];

      calendarEvents.forEach((event) => {
        let styleNameAux!: string;
        styleNameAux = event.styleName;
        if (styleNameAux === 'eventCyan') {
          styleNameAux = 'eventYellow';
        }
        const calendarEvent: ICalendarEvent = {
          id: event.id,
          name: event.titulo,
          start: new Date(event.dataInicio.toString().includes(' ')
            ? dayjs(event.dataInicio).utc(true).format().toString()
              .substring(0, event.dataInicio.toString().lastIndexOf(':') + 3)
            : event.dataInicio.toString().substring(0, event.dataInicio.toString().lastIndexOf(':') + 3)),
          end: new Date(event.dataFim.toString().includes(' ')
            ? dayjs(event.dataFim).utc(true).format().toString()
              .substring(0, event.dataFim.toString().lastIndexOf(':') + 3)
            : event.dataFim.toString().substring(0, event.dataFim.toString().lastIndexOf(':') + 3)),
          color: this.colors.get(styleNameAux) || '',
          styleName: styleNameAux,
          timed: !event.diaTodo,
          status: event.situacao,
          clientType: event.tipo,
          cnpj: event.cnpj,
          idProspect: event.idProspect,
          client: event.nome || '',
          createdBy: event.usuarioInclusao ? event.usuarioInclusao.nome : '',
        };

        if (attachEvents) {
          const existentIndex = this.events.findIndex((e) => e.id === calendarEvent.id);

          if (existentIndex > -1) {
            this.events[existentIndex] = { ...calendarEvent };
          } else {
            events.push(calendarEvent);
          }
        } else {
          events.push(calendarEvent);
        }
      });

      this.events = [...events];

      if (!attachEvents) {
        this.lastLoadedPeriod = [start, end];
      }
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    } finally {
      loader.hide();
    }
  }
}
